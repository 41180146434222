import React from "react";
import ReactDOM from "react-dom";
import styles from "./assets/index.module.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { register as sw } from "./serviceWorker";
import { Home } from "./pages/Home";
import { Events } from "./pages/Events";

const root = document.getElementById("root");

if (root) {
  root.className = styles.root;
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route exact component={Home} path={"/"} />
      <Route exact component={Home} path={"/home"} />
      <Route exact component={Events} path={"/events"} />
    </Router>
  </React.StrictMode>,
  root
);

sw({
  onSuccess: () => {
    console.log("[SW] Ready");
  },
});
